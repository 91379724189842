@import url("https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css"); 

@import "_colors";

@font-face {
    font-family: "CircularStd";
    src: url("./_metronic/fonts/CircularStd-Medium.ttf") format("ttf"),
        url("./_metronic/fonts/CircularStd-Medium.otf") format("otf");
}

body {
    font-family: "CircularStd", sans-serif;
    background: #FFF;
}

body.swal2-height-auto {
    height: 100% !important;
}

#kt_content {
    padding: 10px 0;
}

h3 {
    color: rgba(0, 0, 0, .85);
}

.print_table {
    width: 100%;

    th {
        padding: 1rem;
    }
}

.account-seetings-image_box {
    img {
        width: 120px;
        height: 120px;
    }
}

.cover-section {
    .slick-slider {
        .slide_item {
            .slider_bg_img {
                // background-size: auto;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: top center;
            }
        }
    }
}

.hr-admin-table-job-list {
    .css-b62m3t-container {
        z-index: 99;
    }
}

.Select.is-open {
    z-index: 9999 !important;
}

.border_input_box {
    height: calc(1.3em + 1.3rem + 6px) !important;
    border-color: hsl(0, 0%, 70%);
}
.swal2-container.swal2-center.swal2-backdrop-show {
    z-index: 999999;
}
@media print {
    @page {
        // size: 210mm 297mm;  
        margin: 10mm 5mm 10mm 5mm;
    }

    #viewPrint {
        border: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        background: $white !important;

        .row {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .col-md-6 {
                width: 50% !important;
            }
        }
    }
}

.password_view_btn {
    cursor: pointer;
}

.hr-admin-wrapper {
    margin-top: 4rem !important;

    .container-fluid {
        padding: 0 2rem;
    }

    .hr-row-ml {
        margin-left: 250px;
    }
}

.container-fluid {
    padding: 0 5rem;
}

.container-fluid_in {
    padding: 0 8rem !important;
}

.full_label_box {
    width: 214px !important;
}

// Jobs Card style 1
.nav_profile_img {
    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $white;
        padding: 2px;
    }
}

.navbar-nav {
    align-items: center;
}

.search-job-form {
    border-radius: 5px;
    border: 2px solid var(--surface-surface-primary, $white);
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    padding: 10px;
    margin-top: 1.5rem;

    .form-group-div {
        display: flex;
        align-items: center;
    }

    .form-control {
        padding: 0.3rem 2.5rem;
        border: none;
        background: transparent;

        &:focus {
            box-shadow: none;
        }
    }

    .location {
        width: 60%;
        display: flex;
        align-items: center;
    }

    .search-submit {
        border: 1px solid $secondary;
        background: $secondary;
        color: $white;
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
        width: 130px;
        height: 38px;
        padding: 6.4px 15px;
        border-radius: 2px;
    }

    .input-icons i {
        position: absolute;
    }

    .icon {
        padding: 10px;
        color: $secondary;
        text-align: left;
    }

    .search-job {
        position: relative;
        display: flex;
        align-items: center;

        input {
            display: block;
            border: none;
        }

        .ant-select-single {
            width: 220px;
            background: transparent !important;
            border: none;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background-color: transparent !important;
        }

        .searchWrapper {
            border: none;
            background: transparent;
        }
    }
}

.filtering {
    .pagination {
        align-items: flex-end;
        margin-bottom: 0;

        .page-item {
            padding: 2px;

            .page-link {
                padding: 4px 10px;
                font-size: 18px;
                border-radius: 2px;
                border: 1px solid #d9d9d9;
                background: $white;
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }

    .form-select {
        padding: 4px 2px;
        border-radius: 2px;
        border: 1px solid #d9d9d9;
        background: #fff;
        font-size: 18px;
    }
}

.jobs_title_name {
    color: $gray;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
}


// New Jobs Component Card start
.jobs_component_card {
    border: none !important;
    .jobs_card {
        border-radius: 10px;
        background: $white;
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
            0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        padding: 15px;  
    
        h3 {
            font-size: 18px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: $secondary;
        }
    
        p {
            color: #b0b0b0;
            font-size: 12px;
            font-style: normal;
            font-weight: 450;
            line-height: 22px;
            margin-bottom: 0;
    
            span {
                color: $secondary;
            }
        }
    
        .dept {
            display: flex;
            justify-content: space-between;
            padding-bottom: 0px;
    
            p {
                margin-bottom: 0;
            }
        }
    
        .salary {
            p {  
                span {
                    padding-left: 5px;
                    color: #5a5a5a;
                }
            } 
        }

        .saved {
            p {  
                span {
                    padding-left: 5px;
                    color: #5a5a5a;
                }
            } 

            .icon_label_flex {
                display: flex;
                align-items: center;
                line-height: 1; 
            }

            .job_type {
                i {
                    color: #0a6dd9;
                    font-size: 14px;
                } 
                span {
                    color: #000;
                    font-weight: 600;
                }
            }

            .applied {
                i {
                    color: #1bc5bd;
                    font-size: 14px;
                } 
                span {
                    color: #000;
                    font-weight: 600;
                }
            }

            .saved_bookmark { 
                svg {
                    fill: #2196f3;
                    font-size: 12px;
                } 

                span {
                    color: #000;
                    font-weight: 600;
                }
            }

            
        }
    
        .department_box {
            display: flex;
            justify-content: flex-start;
    
            .dept_lable {
                // width: 82px;
                p {  
                    span { 
                        color: #5a5a5a;
                    }
                } 
    
            }
            .dept_content {
                width: 100%;
                p {  
                    span { 
                        color: #5a5a5a;
                    }
                } 
            }
        } 
    
        &:hover {
            background: #e6f7ff;
    
            p span {
                color: #096dd9;
            }
        }
    }
}

// New Jobs Component Card End


.department_component_card {
    border: none !important;

    .department_card {
        border-radius: 10px;
        background: $white;
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
            0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        padding: 15px; 
        display: flex;
        justify-content: space-between;
    
        &:hover {
            background: #e6f7ff;
        }
    
        h3 {
            font-size: 20px;
            margin-bottom: 0;
        }
    
        .bg-orange {
            color: $white;
            background: #fa8c16;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}



.jobs_card_style1 {
    border-radius: 10px;
    background: $white;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    padding: 15px;
    flex: 1 1 292px;
    max-width: 340px;

    // min-height: 150px;
    // position: relative;

    h3 {
        font-size: 18px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: $secondary;
    }

    p {
        color: #b0b0b0;
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: 22px;
        margin-bottom: 0;

        span {
            color: $secondary;
        }
    }

    .dept {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0px;

        p {
            margin-bottom: 0;
        }
    }

    .salary {
        p { 
    
            span {
                padding-left: 5px;
                color: #5a5a5a;
            }
        } 
    }

    .department_box {
        display: flex;
        justify-content: flex-start;

        .dept_lable {
            width: 82px;
            p { 
    
                span { 
                    color: #5a5a5a;
                }
            } 

        }
        .dept_content {
            width: 100%;
            p { 
    
                span { 
                    color: #5a5a5a;
                }
            } 
        }
    }

    .btn_job_card {
        border-radius: 4px;
        background: $secondary;
        color: $white;
        padding: 5px 16px;
        font-size: 14px;

        // position: absolute;
        // bottom: 15px;
        // left: 15px;
    }

    &:hover {
        background: #e6f7ff;

        p span {
            color: #096dd9;
        }
    }
}

.jobs_card_style2 {
    width: 100%;
    border-radius: 10px;
    background: $white;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    padding: 15px;
    margin-bottom: 1.5rem;
    display: block;

    h3 {
        font-size: 20px;
    }

    p {
        font-size: 14px;
        color: $gray;
        margin-bottom: 5px;

        span {
            color: $secondary;
        }
    }

    .dept {
        display: flex;
        justify-content: space-between;
    }

    .saved {
        p {  
            span {
                padding-left: 5px;
                color: #5a5a5a;
            }
        } 

        .icon_label_flex {
            display: flex;
            align-items: center;
            line-height: 1; 
        }

        .job_type {
            i {
                color: #0a6dd9;
                font-size: 14px;
            } 
            span {
                color: #000;
                font-weight: 600;
            }
        }

        .applied {
            i {
                color: #1bc5bd;
                font-size: 14px;
            } 
            span {
                color: #000;
                font-weight: 600;
            }
        }

        .saved_bookmark { 
            svg {
                fill: #2196f3;
                font-size: 12px;
            } 

            span {
                color: #000;
                font-weight: 600;
            }
        }

        
    }

    .btn_job_card {
        border-radius: 4px;
        background: $secondary;
        color: $white;
        padding: 5px 16px;
        font-size: 14px;
    }

    &:hover {
        background: #e6f7ff;

        p span {
            color: #096dd9;
        }
    }
}

.card_transparent {
    background: transparent;
    border: none !important;
}

.short_jobs_info_card {
    width: 100%;
    border-radius: 10px;
    background: $white;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    padding: 20px 20px;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;

    .left_box {
        h3 {
            font-size: 30px;
            font-weight: 500;
            line-height: 24px;
            color: $secondary;
        }

        p {
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;

            strong {
                color: $dark;
            }
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;

            li {
                color: #444;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 34px;

                i {
                    margin-right: 0.5rem;
                    color: #444;
                    line-height: 34px;
                }
            }
        }
    }

    .right_box {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-end;

        .applied_btn {
            border-radius: 30px;
            background: #2f54eb;
            padding: 4px 16px;
            color: $white;
        }

        .shortlist_btn {
            border-radius: 30px;
            background: #03b01f;
            padding: 4px 16px;
            color: $white;
        }

        .closed_btn {
            border-radius: 30px;
            background: #f79e9e;
            padding: 4px 16px;
            color: $white;
        }

        .expired_btn {
            border-radius: 30px;
            background: #c71c37;
            padding: 4px 16px;
            color: $white;
        }

        .intv_btn {
            border-radius: 30px;
            background: #eb2fac;
            padding: 4px 16px;
            color: $white;
        }

        .save_later_btn {
            border-radius: 30px;
            background: $gray;
            padding: 4px 16px;
            color: $white;
        }

        .apply_date_time {
            color: $secondary;
            font-size: 20px;
            font-style: italic;
            font-weight: 400;
            line-height: 28px;
        }

        .date {
            color: #ff2f2f;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;

            i {
                margin-right: 0.5rem;
            }
        }
    }

    &:hover {
        background: #e6f7ff;
    }
}

.unsave_btn {
    border-radius: 30px;
    background: $gray;
    color: $white;
    padding: 13px 16px;
    line-height: 0;
    height: 0;
}

// Dempartment card style 1

.department-card-style1 {
    border-radius: 10px;
    background: $white;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex: 1 1 292px;
    max-width: 320px;

    &:hover {
        background: #e6f7ff;
    }

    h3 {
        font-size: 20px;
        margin-bottom: 0;
    }

    .bg-orange {
        color: $white;
        background: #fa8c16;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.main_contents_div {
    background: $white;
    border-radius: 10px;
    border: 2px solid #ededed;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    padding: 12px;
}

.card_body_box {
    background: $white;
    border-radius: 5px;
    border: 2px solid #ededed;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    padding: 12px;
}

.card_header_with_edit_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        h2 {
            color: #096dd9;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 0;
        }
    }

    .edit_btn_group {
        a {
            color: $secondary;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;

            svg {
                margin-right: 5px;
            }
        }
    }
}

.expanded_btn {
    color: #000;
    font-weight: 700;
    cursor: pointer;
    margin-left: 5px; 
}

.card_items_row_flex {
    display: flex;
    justify-content: flex-start;
    padding: 0.65rem 0.5rem;

    .label_box {
        // width: 35%;
        // max-width: 220px;
        min-width: 186px;
        color: #7a7a7a;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .item_data {
        color: #444;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        // width: 65%;
    }
}

// Profile

.profile_card_main {
    background: $secondary;
    display: flex;
    padding: 15px;
    flex-direction: unset;
    justify-content: space-between;
    align-items: center;

    .left_box {
        display: flex;
        align-items: center;

        .image_box {
            img {
                width: 120px;
                height: 120px;
                object-fit: fill;
                border-radius: 4px;
            }

            padding-right: 15px;
        }

        .info_box {
            h2 {
                color: $white;
            }

            p {
                color: $white;
            }

            .change_photo_btn {
                border-radius: 2px;
                background: $white;
                color: $secondary;
                border: none;
                padding: 3px 5px;
                display: flex;
                align-items: center;
            }

            .profile_upload_btn {
                width: 100% !important;
                height: auto !important;
                border-radius: 2px !important;
                padding: 3px 6px;
                display: flex;
                align-items: center;

                &:after {
                    content: " " !important;
                    font-family: " " !important;
                    top: 0 !important;
                }
            }

            .avatar-upload {
                position: unset !important;

                .avatar-edit {
                    right: 0 !important;
                    top: 0 !important;
                    position: unset !important;
                }
            }
        }

        .info_box2 {
            h2 {
                color: $white;
            }

            p {
                color: $white;
                margin-bottom: 0.5rem;

                svg {
                    margin-right: 1rem;
                }
            }
        }
    }

    .right_box {
        .progress {
            width: 120px;
            height: 120px;
            line-height: 120px;
            background: none;
            margin: 0 auto;
            box-shadow: none;
            position: relative;
            background: transparent;
        }

        .progress:after {
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 12px solid $white;
            position: absolute;
            top: 0;
            left: 0;
        }

        .progress>span {
            width: 50%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            z-index: 1;
        }

        .progress .progress-left {
            left: 0;
        }

        .progress .progress-bar {
            width: 100%;
            height: 100%;
            background: none;
            border-width: 12px;
            border-style: solid;
            position: absolute;
            top: 0;
        }

        .progress .progress-left .progress-bar {
            left: 100%;
            border-top-right-radius: 80px;
            border-bottom-right-radius: 80px;
            border-left: 0;
            -webkit-transform-origin: center left;
            transform-origin: center left;
        }

        .progress .progress-right {
            right: 0;
        }

        .progress .progress-right .progress-bar {
            left: -100%;
            border-top-left-radius: 80px;
            border-bottom-left-radius: 80px;
            border-right: 0;
            -webkit-transform-origin: center right;
            transform-origin: center right;
            animation: loading-1 1.8s linear forwards;
        }

        .progress {
            .progress-value {
                width: 90%;
                height: 90%;
                border-radius: 50%;
                color: $white;
                text-align: center;
                position: absolute;
                top: 5%;
                left: 5%;
                line-height: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h3 {
                    margin-bottom: 5px;
                    color: $white;
                }

                p {
                    font-size: 9px;
                    margin-bottom: 0;
                }
            }
        }

        .progress.blue .progress-bar {
            border-color: $secondary;
        }

        .progress.blue .progress-left .progress-bar {
            animation: loading-2 1.5s linear forwards 1.8s;
        }

        @keyframes loading-1 {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }

        @keyframes loading-2 {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(144deg);
                transform: rotate(144deg);
            }
        }

        @media only screen and (max-width: 990px) {
            .progress {
                margin-bottom: 20px;
            }
        }
    }
}

.jobs_post_popup {
    border-radius: 10px;
    background: linear-gradient(270deg, #1367a6 0%, #0b3657 100%);
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    padding: 25px;

    .header_div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .logo {
            border-radius: 20px;
            border: 1px solid $white;
            background: var(--color-selected,
                    linear-gradient(270deg, #004578 0%, #0078d4 100%));
        }

        .right_btn_groups {
            display: flex;

            .export_image {
                border-radius: 32px;
                border: 1px solid $white;
                background: #003a8c;
                box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
                display: flex;
                padding: 4px 15px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                color: $white;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                margin-right: 1rem;
            }

            .share_btn {
                border-radius: 42px;
                border: 1px solid $secondary;
                background: $white;

                /* drop-shadow/button-secondary */
                box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
                display: flex;
                padding: 4px 15px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                color: $secondary;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }
        }
    }

    .title_div {
        h2 {
            color: var(--surface-surface-primary, $white);
            text-shadow: 0px 9px 28px rgba(0, 0, 0, 0.05),
                0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.12);
            font-size: 96px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
        }
    }

    .jobs_title_card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $secondary;
        border-left: none;
        border-right: none;
        padding: 15px 0;

        .jobs_title {
            h2 {
                color: $secondary;
                font-size: 48px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;
            }

            p {
                color: #e6f7ff;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 0;
            }
        }

        .vacancy {
            h2 {
                color: #e6f7ff;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 0;

                strong {
                    color: $white;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                }
            }
        }
    }

    .jobs_discriptions {
        margin-top: 1rem;

        .item_row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 10px 0;

            span {
                color: #e6f7ff;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;

                strong {
                    color: $white;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                }
            }
        }
    }
}

// HR Admin

.brand-dark {
    .brand {
        background-color: transparent;
        position: relative;

        .brand-toggle {
            position: absolute;
            right: -68%;
            background: #fff;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: none;
        }
    }
}

.brand-logo {
    .img-fluid {
        width: 100%;
        height: auto;
    }
}

.aside-fixed {
    .aside {
        z-index: 9999;
    }
}

.hr_admin_table {
    thead {
        border-bottom: none;
        background: $primary;
        color: $white;

        th {
            border-bottom: none;
            font-size: 16px;
        }
    }

    .hr_admin_table_tr {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        border-bottom: 1px solid #ddd;

        td {
            border-top: none;
            font-size: 14px;
        }

        .table_action_btn {
            .dropdown {
                button {
                    background: transparent;
                    border: none;

                    &:hover {
                        background: transparent !important;
                        border: none !important;
                    }
                }
            }
        }
    }
}

.add_header_card {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 2rem;

    h5 {
        font-size: 25px;
        font-weight: 500;
    }
}

.data_not_found_box {
    width: 100%;
    display: flex;
    align-items: center;
    height: 500px;
    justify-content: center;
    opacity: .4;

    img {
        width: 400px;
        height: auto;
    }
}

.ant-dropdown {
    z-index: 9999;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.withdraw-btn {
    border-radius: 30px;
    background: #dc3545;
    color: #FFF;
    padding: 13px 16px;
    line-height: 0;
    height: 0;
    border: 1px solid #dc3545 !important;
}

.social-icons a {
    display: inline-block;
    margin-right: 10px;
    /* Adjust spacing between icons if necessary */
}

.social-icons {
    .fa {
        padding: 20px;
        font-size: 30px;
        width: 70px;
        text-align: center;
        text-decoration: none;
        margin: 5px 2px;
    }

    .fa:hover {
        opacity: 0.7;
    }

    .fa-facebook {
        background: #3B5998;
        color: white;
    }

    .fa-twitter {
        background: #55ACEE;
        color: white;
    }

    .fa-linkedin {
        background: #007bb5;
        color: white;
    }

    .fa-youtube {
        background: #bb0000;
        color: white;
    }

    .fa-instagram {
        background: #125688;
        color: white;
    }
}

.password-input-container {
    position: relative;
    // display: inline-block;
}

.password-input-container .form-control {
    padding-right: 40px;
    /* Adjust as per your icon size */
}

.password-input-container .password_view_btn {
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust as per your preference */
    transform: translateY(-50%);
    cursor: pointer;
}

.doc-action-btn {
    bottom: 4px;
    float: inline-end;
}

.doc-background {
    position: relative;
    width: 100%;
    height: 150px;
    overflow: hidden;
    text-align: center;
}

.doc-preview {

    width: 60%;
    height: auto;
    // position: absolute;
    // top: 2px;
    // left: '0'
}

.doc-gallary {
    background-color: #dcdbdf;
    padding: 10px;
    height: 245px;
    border-right: 15px solid white;
}

.fa-edit {
    color: #777171 !important;
}
.remaining_date_div {
    color: #ff2f2f;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;

    p {
        margin-bottom: 0;
    }

    .remaining_day {
        font-size: 15px;
        padding-left: 20px;
    }
}
.not_found_card {
    text-align: center; 
    
    .icon {
        width: 300px;
        height: auto;
    }

    .not_found{
        font-size: 40px;
        color: $primary; 
 
      .page{
        margin: 2rem 0;
        font-size: 20px;
        font-weight: 600;
        color: #444;
      }
    }
}


.auto_dismiss_close_btn{
    position: absolute;
    right: 13px;
    background: transparent;
    border: none;
    font-size: 20px;
    padding: 0;
    top: 4px;
} 

.custom_alert_success {
    color: #3fa10e;
    background-color: #dff5e1;
    font-size: 15px;
}

.custom_alert_danger {
    color: #F64E60;
    background-color: #FFE2E5;
    font-size: 15px;
}