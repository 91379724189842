 .multi_range_slider {
    padding: 6px 0 36px 0px;

    .form-label {
        margin-bottom: 16px;
        display: block;
        padding-left: 6px;
    }
    
     .slider {
         position: relative;
         width: 100%;
     }

     .slider__track,
     .slider__range,
     .slider__left-value,
     .slider__right-value {
         position: absolute;
     }

     .slider__track,
     .slider__range {
         border-radius: 3px;
         height: 5px;
     }

     .slider__track {
         background-color: #B0B0B0;
         width: 100%;
         z-index: 1;
     }

     .slider__range {
         background-color: #40A9FF;
         z-index: 2;
     }

     .slider__left-value,
     .slider__right-value {
         color: #0050b3;
         font-size: 12px;
         margin-top: 16px;
     }

     .slider__left-value {
         left: 6px;
     }

     .slider__right-value {
         right: -4px;
     }

     /* Removing the default appearance */
     .thumb,
     .thumb::-webkit-slider-thumb {
         -webkit-appearance: none;
         -webkit-tap-highlight-color: transparent;
     }

     .thumb {
         pointer-events: none;
         position: absolute;
         height: 0;
         max-width: 290px;
         width: 100%;
         outline: none;
     }

     .thumb--left {
         z-index: 3;
     }

     .thumb--right {
         z-index: 4;
     }

     /* For Chrome browsers */
     .thumb::-webkit-slider-thumb {
         background-color: #40A9FF;
         border: none;
         border-radius: 50%;
         box-shadow: 0 0 1px 1px #4ea9f3;
         cursor: pointer;
         height: 18px;
         width: 18px;
         margin-top: 4px;
         pointer-events: all;
         position: relative;
     }

     /* For Firefox browsers */
     .thumb::-moz-range-thumb {
         background-color: #f1f5f7;
         border: none;
         border-radius: 50%;
         box-shadow: 0 0 1px 1px #ced4da;
         cursor: pointer;
         height: 18px;
         width: 18px;
         margin-top: 4px;
         pointer-events: all;
         position: relative;
     }
 }