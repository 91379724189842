:root { 
    --primary-color: #003A8C; // Default color
    --secondary-color: #40A9FF; // Default color
}

 
$primary: var(--primary-color);
$secondary: var(--secondary-color); 
$subsidiary: #FA8C16;
$red: #FF2929;
$green: #52C41A;
$white: #FFF;
$royalBlue: #1A2942; 
$gray: #444;
$dark: #000;



// 40A9FF
// 003A8C
// 1A2942
// Subsidiary Color- FA8C16 
// Alert Colors- FF2929, 52C41A