// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

//Syncfusion
@import url("https://cdn.syncfusion.com/ej2/material.css");

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight>pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json>pre {
  background: none #fff !important;
}

.example-code>.json {
  background: none #fff !important;
}

.symbol.symbol-45>svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

// Custom CSS
.text-filter {
  margin-top: 10px;
}

// .header-fixed.subheader-fixed.subheader-enabled .wrapper {
//   padding-top: 80px !important;
// }

.badge-light-danger {
  color: #f1416c;
  background-color: #fff5f8;
  font-weight: bold;
}

.badge-light-success {
  color: #50cd89;
  background-color: #e8fff3;
  font-weight: bold;
}

input {
  &::placeholder {
    text-transform: capitalize;
  }
}

.form-control {
  height: calc(1.5em + 1.3rem + 6px) !important;
}

// wizard step
.wizard.wizard-1 .wizard-nav .wizard-steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
  margin-left: 3rem;
  margin-right: 3rem;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon {
  color: #b5b5c3;
  font-size: 3.75rem;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
  color: #7e8299;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 0.75rem;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow {
  color: #7e8299;
  font-size: 1.25rem;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow.last {
  display: none;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-label .wizard-icon,
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-label .wizard-icon {
  color: #3699ff;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-label .wizard-icon svg g [fill],
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-label .wizard-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-label .wizard-icon svg:hover g [fill],
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-label .wizard-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-label .wizard-title,
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-label .wizard-title {
  color: #3699ff;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-arrow,
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-arrow {
  color: #3699ff;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-arrow svg g [fill],
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-arrow svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-arrow svg:hover g [fill],
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-arrow svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon {
    font-size: 3.75rem;
  }

  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
    margin-top: 0.75rem;
  }

  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow {
    font-size: 1.1rem;
  }
}

@media (max-width: 767.98px) {
  .wizard.wizard-1 .wizard-nav .wizard-steps {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }

  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon {
    font-size: 1.5rem;
    margin-right: 1.25rem;
  }

  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow {
    font-size: 1rem;
  }

  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow.last {
    display: block;
  }
}

// img select in create warehouse
.img-options {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.active-img {
  position: relative;
  display: inline-block;

  img,
  svg {
    border: 2px solid #3699ff;
    cursor: pointer;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}

.right-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-65%, -80%);
  font-size: 20px;
  color: #3699ff;
}

// custom file upload
.file-upload-field {
  display: inline-block;
  width: 100%;
  padding: 120px 0 0 0;
  height: 100px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  // background: url("/media/svg/icons/Files/Upload.svg") center center no-repeat #f3f6f9;
  border-radius: 10px;
  background-size: 60px 60px;
  cursor: pointer;
}

// multiselect input design
.searchWrapper {
  background: #fefefe;
  border-radius: 0.42rem;
  padding: 8px;
  min-height: 22px;
  position: relative;
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
}

.multiSelectContainer input {
  margin-top: 0;

  &::placeholder {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    color: #b5b5c3;
  }
}

// multidate picker class
.rmdp-container {
  display: block !important;

  &::after {
    content: "\1F4C6";
    right: 30px;
    top: 50%;
    font-size: 16px;
    position: absolute;
  }

  input {
    width: 100%;
    background-color: #f3f6f9 !important;
    padding: 20px;
    border-color: #f3f6f9;
    color: #3f4254;
    transition: color 0.15s ease, background-color 0.15s ease,
      border-color 0.15s ease, box-shadow 0.15s ease;
    display: block;
    width: 100%;
    //height: calc(1.5 em + 1.3 rem + 2 px);
    padding: 0.65 rem 1 rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3f4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e4e6ef;
    border-radius: 0.42 rem;
    box-shadow: none;
    border: 0px;
    cursor: pointer;

    &:focus {
      background-color: #ebedf3;
      border: 0px;
    }
  }
}

// antd select
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f3f6f9;
  border: 0px;
  border-radius: 5px;
}

.ant-select {
  background: #f3f6f9 !important;
  padding: 6px;
  border-radius: 5px;
}

.ant-picker {
  background-color: #f3f6f9;
  border: 0px;
  border-radius: 5px !important;
}

.ant-picker-input>input {
  padding: 7px 2px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 5px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: #40a9ff;
  }
}

.ql-toolbar.ql-snow {
  background-color: #f3f6f9;
  border-radius: 5px 5px 0 0;
  border: none;
  border-bottom: 1px solid #b5b5c3;
}

.ql-container.ql-snow {
  background-color: #f3f6f9;
  border-radius: 5px;
  border: none;
}

// chalan update
.card_box_bg {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 30px 5px rgba(63, 63, 63, 0.04);
  font-family: "poppins";
}

.white-bg-img-chalan {
  // background-image: url(/media/bg/bg2.png) !important;
  background-size: contain !important;
}

.grid {
  display: grid;
  grid-template-columns: 2fr 6fr;
  padding: 0 20px;
  margin-bottom: 5px;
}

.table-bordered {
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-color: #fff;
}

.cart_thead {
  font-family: "poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #25262b;
  background-color: #cbcbcb;
  border-radius: 4px 4px 0px 0px;
}

.table-bordered .order_item_list {
  background-color: #f3f3f3;
}

.grid .chalan-value {
  border-bottom: 3px dotted #a7a5a5;
  text-align: justify;
}

.signature {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 50px;
}

.signature h6 {
  text-decoration: overline;
}

.order-details-border .gird {
  display: grid;
  grid-template-columns: 3fr 2fr;
  font-size: 14px;
  margin-bottom: 5px;
}

.is_required {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #f64e60;
}

.profile_photo {
  border-radius: 5px;
  margin-right: 30px;
  margin-left: 10px;
  float: left;
  width: 120px;
  height: 120px;
}

.edit_product_img {
  border-radius: 5px;
  width: 90%;
  height: 300px;
}

.profile_photo_edit_icon {
  right: -18px;
  bottom: 5px;
}

.profile_edit {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #25262b;
  padding: 6px 12px;
  //background: $primry-color;
  border-radius: 4px;
  position: relative;
  right: 30px;
  float: right;
  top: -75px;
}

.profile_info_div {
  padding-top: 1.5rem;

  .profile_contact_info {
    font-style: normal;

    h4 {
      font-weight: 500;
      font-size: 36px;
      line-height: 42px;
      color: #25262b;
    }

    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #848484;
      margin-bottom: 4px;
    }
  }
}

.text_area {
  height: "auto !important";
  background-color: "#F3F6F9";
  border-color: "#F3F6F9";
  color: "#3F4254";
  display: "block";
  width: "100%";
  padding: "0.65rem 1rem";
  font-size: "1rem";
  font-weight: "400";
  line-height: 1.5;
  background-clip: "padding-box";
  border: "1px solid #E4E6EF";
  border-radius: "0.42rem";
  box-shadow: "none";
  outline: "none";
}

.bengalmeat-logo {
  @media screen and (max-width: 1200px) {
    width: 100px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.duration {
  padding: 9px;
  border: 1px solid #ebe2e2;
}

.form-control--secondary {
  border: 1px solid #e6e6e6;
  height: 2.5rem;
}

.text-right {
  text-align: right;
}

.card {
  border: 2px solid #ededed !important;
}


iframe#webpack-dev-server-client-overlay{display:none!important}